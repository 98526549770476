<template>
  <div class="card">

    <div class="card-header border-0">
      <div class="row">
        <div class="col-6">
          
          <h3 class="mb-0 text-left">
            {{ getEventData.name }}'s Images / Files
            <small>
              <router-link :to="{name: 'AdminEventsEdit', params: { id: eventId }}">
                (Edit Event Details)
              </router-link>  
            </small>
          </h3>

          <p class="m-0 p-0"><small>Event Time: {{ getEventData.datetime }}</small></p>
          <p class="m-0 p-0"><small>Registration Closing time {{ getEventData.closing_datetime }}</small></p>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <fieldset class="border p-3 rounded bg-silver">
            <legend>Event Metadata</legend>
            <div class="row">
              <div class="form-group col-8">
                <label class="form-control-label mb-0">
                  Upload New Image
                  <small>(<a :href="getEventData?.image" target="_blank">view</a>)</small>
                </label>
                <div class="input-group">
                  <div class="custom-file pt-3">
                    <input type="file" class="custom-file-input" id="image" accept="image/*"
                           @change="handleFileUpload($event, 'image')"
                    />
                    <label class="custom-file-label" for="image">Choose file</label>
                  </div>

                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary" @click="upload('image')">
                      UPLOAD
                    </button>
                  </div>
                  <div class="input-group-append ml-1" v-if="getEventData?.image">

                    <a :href="getEventData?.image" target="_blank" class="btn btn-info"  >
                      View
                    </a>

                  </div>
                  <div class="input-group-append ml-1" v-if="getEventData?.image">
                    <button type="button" class="btn btn-danger" @click="removeImage('image',getEventData?.image)" >
                      Delete
                    </button>
                  </div>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="form-group col-8">
                <label class="form-control-label mb-0">
                  Upload New Map Image <small>(<a :href="getEventData?.map_image" target="_blank">view</a>)</small>
                </label>
                <div class="input-group">
                  <div class="custom-file pt-3">
                    <input type="file" class="custom-file-input" id="MapImage" accept="image/*" @change="handleFileUpload($event, 'map_image')"
                    />
                    <label class="custom-file-label" for="MapImage">Choose file</label>
                  </div>
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary" @click="upload('map_image')">UPLOAD</button>
                  </div>
                  <div class="input-group-append ml-1" v-if="getEventData?.map_image">

                    <a :href="getEventData?.map_image" target="_blank" class="btn btn-info"  >
                      View
                    </a>

                  </div>
                  <div class="input-group-append ml-1" v-if="getEventData?.map_image">
                    <button type="button" class="btn btn-danger" @click="removeImage('map_image',getEventData?.map_image)" >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-8">
                <label class="form-control-label mb-0">
                  Upload New Technical Guide <small>(<a :href="getEventData?.technical_guide" target="_blank">view</a>)</small>
                </label>
                <div class="input-group">
                  <div class="custom-file pt-3">
                    <input type="file" class="custom-file-input" id="TechnicalGuide2" @change="handleFileUpload($event, 'technical_guide')"
                    />
                    <label class="custom-file-label" for="TechnicalGuide">Choose file</label>
                  </div>
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary" @click="upload('technical_guide')">UPLOAD</button>
                  </div>
                  <div class="input-group-append ml-1" v-if="getEventData?.technical_guide">

                    <a :href="getEventData?.technical_guide" target="_blank" class="btn btn-info"  >
                      View
                    </a>

                  </div>
                  <div class="input-group-append ml-1" v-if="getEventData?.technical_guide">
                    <button type="button" class="btn btn-danger" @click="removeImage('technical_guide',getEventData?.technical_guide)" >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-8">
                <label class="form-control-label mb-0">
                  Course Details <small>(<a :href="getEventData?.course_details" target="_blank">view</a>)</small>
                </label>
                <div class="input-group">
                  <div class="custom-file pt-3">
                    <input type="file" class="custom-file-input" id="TechnicalGuide" @change="handleFileUpload($event, 'course_details')"
                    />
                    <label class="custom-file-label" for="TechnicalGuide">Choose file</label>
                  </div>
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary" @click="upload('course_details')">UPLOAD</button>
                  </div>
                  <div class="input-group-append ml-1" v-if="getEventData?.course_details">

                    <a :href="getEventData?.course_details" target="_blank" class="btn btn-info"  >
                      View
                    </a>

                  </div>
                  <div class="input-group-append ml-1" v-if="getEventData?.course_details">
                    <button type="button" class="btn btn-danger"  @click="removeImage('course_details',getEventData?.course_details)">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </fieldset>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'AdminEventImages',

  data() {
    return {
      form: {},
      eventId: this.$route.params.id,
    };
  },

  computed: {
    ...mapGetters('event', ['event']),

    getEventData() {
      if (!this.event) return {};
      return Object.assign({}, this.event)
    }
  },

  mounted() {
    this.$store.dispatch('event/event', this.eventId);
  },

  methods: {
    handleFileUpload(event, name) {
      this.form[name] = event.target.files[0];
    },

    async upload(name) {
      if(!this.form[name]) return ;

      const formData = new FormData();
      formData.append(name, this.form[name])
      formData.append('key', name)
      formData.append('event_id', this.eventId)

      await this.$store.dispatch('event/update_metadata', formData).then(res => {
        if (res && res.status) {
          this.getEventData.image = res.data.url
        }
      });
    },
    async removeImage(name,fileUrl) {

      const formData = new FormData();
      formData.append('fileUrl', fileUrl)
      formData.append('key', name)
      formData.append('event_id', this.eventId)

      await this.$store.dispatch('event/remove_metadata', formData).then(res => {
        if (res && res.status) {
          //this.getEventData.image = res.data.url
          this.getEventData.image = ''
        }
      });
    },
  }
}
</script>
<style scoped>

</style>
